<template>
  <div class="load-card">
    <!-- spinning animation -->
    <div class="spinning" />
    <!-- loading text -->
    <span class="card-text">
      Loading Colors
    </span>
  </div>
</template>

<script>
export default {
  name: 'LoadingText'
}
</script>

<style></style>
